<williams-ui-platform-stack-layout orientation="vertical" [gap]="'0px'">
    <williams-ui-platform-stack-layout>
        <img class="ebbhomeimage" [src]="headerImageUrl" alt="Header Image"/>
    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout orientation="horizontal" class="horizontalLayout" gap="0">
        <div class="noticeDiv">
            <div>
                <div class="noticeBox" style="background-color: #FFFFFF">
                    <div class="d-flex overallBox" *ngIf="showCriticalNotices">
                        <div *ngIf="noticeData && noticeData.length > 0; else norecordsfoundforcritical" orientation="vertical" style="width:100%">
                            <williams-ui-platform-stack-layout orientation="horizontal">
                                <williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }">
                                    <h2 class="titleText">{{ CRITICAL_NOTICES }}</h2>
                                </williams-ui-platform-stack-layout>
                                <williams-ui-platform-stack-layout [align]="{ horizontal: 'end' }">
                                    <williams-ui-platform-button classList="k-button-discovery d-flex maticonclass" fillMode="outline" themeColor="primary"
                                                                 [matIcon]="'open_in_new'"
                                                                 (click)="navigateToNotices('C')">
                                        {{ SHOW_ALL }}
                                    </williams-ui-platform-button>
                                </williams-ui-platform-stack-layout>
                            </williams-ui-platform-stack-layout>
                            <div class="noticeBoxList">
                                <williams-ui-platform-stack-layout orientation="vertical" class="noticeBox">
                                    <ul class="noticeList">
                                        <li *ngFor="let notice of notices" class="noticeItem" (click)="navigateToNoticesDetails(notice)">
                                            <williams-ui-platform-badge [customStyles]="criticalBadge" class="badge" rounded="large" badge-content>{{ notice.category }}</williams-ui-platform-badge>
                                            <div class="itemText truncateCriticalNotices">{{ notice.title }}</div>
                                            <h6 class="dateText" footer>{{transFormDate(notice.effectiveDate)}}</h6>
                                        </li>
                                    </ul>
                                </williams-ui-platform-stack-layout>
                            </div>
                        </div>
                        <ng-template #norecordsfoundforcritical>
                            <div orientation="vertical" style="width: 100%; display: flex" >
                                <williams-ui-platform-stack-layout orientation="vertical" class="noticeBox">
                                    <williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }" class="w-mb-0" style="gap: 0px">
                                        <div class="titleText">{{ CRITICAL_NOTICES }}</div>
                                    </williams-ui-platform-stack-layout>
                                    <div class="noticeBoxList">
                                        <williams-ui-platform-stack-layout orientation="vertical" class="noticeBox">
                                            <ul class="noticeList">
                                                <li class="noticeItem" (click)="navigateToNoticesDetails(notices)">
                                                    <div class="itemText">{{THERE_ARE_NO_CRITICAL_NOTICES}}</div>
                                                </li>
                                            </ul>
                                        </williams-ui-platform-stack-layout>
                                    </div>
                                </williams-ui-platform-stack-layout>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="noticeBox" style="background-color: #FFFFFF">
                    <div class="d-flex overallBox" *ngIf="showPlannedOutageNotices">
                        <div *ngIf="plannedOutageData && plannedOutageData.length > 0; else norecordsfoundforplanned" orientation="vertical" style="width:100%">
                            <williams-ui-platform-stack-layout orientation="horizontal">
                                <williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }">
                                    <h2 class="titleText">{{ PLANNED_SERVICE_OUTAGE }}</h2>
                                </williams-ui-platform-stack-layout>
                                <williams-ui-platform-stack-layout [align]="{ horizontal: 'end' }">
                                    <williams-ui-platform-button classList="k-button-discovery d-flex" fillMode="outline" themeColor="primary"
                                                                 [matIcon]="'open_in_new'"
                                                                 (click)="navigateToNotices('P')">
                                        {{ SHOW_ALL }}
                                    </williams-ui-platform-button>
                                </williams-ui-platform-stack-layout>
                            </williams-ui-platform-stack-layout>
                            <div class="noticeBoxList">
                                <williams-ui-platform-stack-layout orientation="vertical" class="noticeBox">
                                    <ul class="noticeList">
                                        <li *ngFor="let notice of plannedOutageData"  class="noticeItem" (click)="navigateToPlannedNoticesDetails(notice)">
                                            <williams-ui-platform-badge [customStyles]="plannedBadge" class="badge" rounded="large" badge-content>{{ notice.category }}</williams-ui-platform-badge>
                                            <div class="itemText truncateCriticalNotices">{{ notice.title }}</div>
                                            <h6 class="dateText" footer>{{transFormDate(notice.effectiveDate)}}</h6>
                                        </li>
                                    </ul>
                                </williams-ui-platform-stack-layout>
                            </div>
                        </div>
                        <ng-template #norecordsfoundforplanned>
                            <div orientation="vertical" style="width: 100%; display: flex" >
                                <williams-ui-platform-stack-layout orientation="vertical" class="noticeBox">
                                    <williams-ui-platform-stack-layout [align]="{ horizontal: 'start' }" class="w-mb-0" style="gap: 0px">
                                        <div class="titleText">{{ PLANNED_SERVICE_OUTAGE }}</div>
                                    </williams-ui-platform-stack-layout>
                                    <div class="noticeBoxList">
                                        <williams-ui-platform-stack-layout orientation="vertical" class="noticeBox">
                                            <ul class="noticeList">
                                                <li class="noticeItem" (click)="navigateToPlannedNoticesDetails(notices)">
                                                    <div class="itemText">{{THERE_ARE_NO_PLANNED_SERVICE}}</div>
                                                </li>
                                            </ul>
                                        </williams-ui-platform-stack-layout>
                                    </div>
                                </williams-ui-platform-stack-layout>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <img class="mapImage" [src]="imageUrl" alt="Discovery Image" (click)="openImageModal()"/>
        <div class="map-overlay" *ngIf="isImageModalOpen" (click)="closeImageModal()">
            <div class="map-content">
                <img [src]="imageUrl" alt="Discovery Image"/>
            </div>
        </div>

    </williams-ui-platform-stack-layout>
    <williams-ui-platform-stack-layout [gap]="'24px'"
                                       class="d-flex w-px-8 w-py-4 flex-column w-bg-white-1 w-color-compTxt-1"
                                       orientation="horizontal">
        <williams-ui-platform-stack-layout orientation="vertical" [align]="{ horizontal: 'center' }"
                                           class="w-flex-basis-6 flex-grow-0 w-position-relative">
            <div class="w-my-20 w-display-4">{{ CONTACTS }}</div>
        </williams-ui-platform-stack-layout>
        <div class="flex-grow-0">
            <img class="greyline" src="assets/w-ui-angular/images/Separator.png" alt=""/>
        </div>
        <williams-ui-platform-stack-layout orientation="vertical" class="w-position-relative">
            <div class="verticallyCenter">
                <h3 class="w-h1-bold">{{ ADDRESS_1 }}</h3>
                <h6 class="w-m-0">{{ ADDRESS_2 }}</h6>
                <h6 class="w-m-0">{{ ADDRESS_3 }}</h6>
                <h6 class="w-m-0">{{ ADDRESS_4 }}</h6>
            </div>
        </williams-ui-platform-stack-layout>
        <div *ngIf="environment.showContacts === 'true'" class="w-bg-white-1 peopleimgcontainer w-position-relative w-mr-13">
            <williams-ui-platform-stack-layout orientation="vertical" class="center">
                <div class="d-flex flex-wrap flex-justify-center">
                    <img class="colored-image" src="assets/w-ui-angular/images/people.png" alt=""/>
                </div>
                <williams-ui-platform-stack-layout>
                    <williams-ui-platform-button
                            fillMode="outline"
                            classList="d-flex w-h1-bold w-mt-2 k-button-discovery"
                            themeColor="primary"
                            [matIcon]="'open_in_new'"
                            (click)="navigateToContact()">
                        {{ VIEW_ALL_CONTACTS }}
                    </williams-ui-platform-button>
                </williams-ui-platform-stack-layout>
            </williams-ui-platform-stack-layout>
        </div>
    </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>