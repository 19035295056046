import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { Router } from "@angular/router";
import {
  INDEX_OF_CUSTOMERS_TITLE,
  VIEW_DOWNLOAD_IOC_TITLE,
  FILE_TYPE,
  VIEW,
  DOWNLOAD,
  PDF_VIEWER,
} from "../constant";
import { indexofCustomersUrl } from "../../api-endpoints";
import {environment} from "../../environments/environment";

@Component({
  selector: "williams-ui-platform-index-of-customers",
  templateUrl: "./index-of-customers.component.html",
  styleUrls: ["./index-of-customers.component.scss"],
})
export class IndexOfCustomersComponent implements OnInit {
  eyeIcon = "assets/w-ui-angular/images/eye.svg";
  hoveredEyeIcon = "assets/w-ui-angular/images/eye.svg";
  activedEyeIcon = "assets/w-ui-angular/images/eye-white.svg";
  downLoadIcon = "assets/w-ui-angular/images/downloadarrow.svg";
  hovereddownLoadIcon = "assets/w-ui-angular/images/downloadarrow.svg";
  activedownLoadIcon = "assets/w-ui-angular/images/downloadarrowWhite.svg";
  form!: FormGroup;
  fileTypeDropdownData: Array<any> = [
    { name: "Portable Document Format (PDF)", value: "pdf" },
    { name: "Tab Delimited Text (TSV)", value: "tsv" },
  ];
  selectedFileType: string = "PDF";
  destroy$: Subject<boolean> = new Subject<boolean>();
  INDEX_OF_CUSTOMERS_TITLE = INDEX_OF_CUSTOMERS_TITLE;
  VIEW_DOWNLOAD_IOC_TITLE = VIEW_DOWNLOAD_IOC_TITLE;
  FILE_TYPE = FILE_TYPE;
  VIEW = VIEW;
  DOWNLOAD = DOWNLOAD;
  newIndexOfCustomersUrl = indexofCustomersUrl.replace('{location}', environment.fileLocation);
  constructor(private fb: FormBuilder, private router: Router) {
    this.formInit();
  }

  formInit() {
    this.form = this.fb.group({
      filetype: ["pdf", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  selectionChange(event: any) {
    this.selectedFileType = event.value.value;
  }

  view() {
    if (this.selectedFileType === "tsv") {
      window.open(
        `${this.newIndexOfCustomersUrl}/IndexofCustomers?fileType=TSV&tsv=Y`,
        "_blank"
      );
    } else {
      this.router.navigate([]).then((result) => {
        window.open(`${PDF_VIEWER}?fileName=IndexofCustomers`, "_blank");
      });
    }
  }

  download() {
    if (this.selectedFileType === "tsv") {
      window.open(
        `${this.newIndexOfCustomersUrl}/IndexofCustomers?fileType=TSV`,
        "_blank"
      );
    } else {
      window.location.href =
        `${this.newIndexOfCustomersUrl}/IndexofCustomers?fileType=PDF`;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
