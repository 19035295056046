import { Component, Input, OnInit } from '@angular/core';
import {catchError, Subject, takeUntil} from 'rxjs';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { HTMLService } from '../store/services/hml.report.service';
import { ActivatedRoute, Router } from '@angular/router';
import {environment} from "../../environments/environment";

@Component({
  selector: 'williams-ui-platform-html-viewer',
  templateUrl: './html-viewer.component.html',
  styleUrls: ['./html-viewer.component.scss']
})
export class HtmlViewerComponent implements OnInit {
  fileName: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  public innerHTMLValue: SafeHtml = '';
  constructor(private htmlService: HTMLService,
    private route: ActivatedRoute, private sanitizer: DomSanitizer, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).
    subscribe((params) => {
      if (params['id']) {
        this.fileName = params['id'] + ".htm";
        this.showHTML()
      }
    });
    this.route.queryParams.pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params['id']) {
          this.fileName = params['id'] + ".htm";
          this.showHTML()
        }
  });
  }

  showHTML() {
    this.htmlService.getHTMLData(environment.fileLocation, this.fileName)
      .pipe(takeUntil(this.destroy$), catchError((error, caught) => {
        if(error.status === 404) {
          this.router.navigateByUrl("error");
        }
        return caught;
      }))
      .subscribe((res: any) => {
        this.innerHTMLValue = this.sanitizer.bypassSecurityTrustHtml(res);
      })
  }
  /*
 function for destroy angular component with lifycycle-hook
*/
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

}






