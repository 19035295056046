import { Injectable } from '@angular/core';
import {
    DefaultDataService,
    HttpUrlGenerator
  } from '@ngrx/data';
import { map, Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { CRITICALNOTICES } from '../entity-meta-data/entity-metadata';
import { StaticResponse } from '../model/base-response';
import { noticesURL } from '../../../api-endpoints';
import { NoticesInterface } from '../model/notices.interface';

@Injectable()
export class CriticalNoticesDataService extends DefaultDataService<NoticesInterface> {
  constructor(http: HttpClient, 
              httpUrlGenerator: HttpUrlGenerator
              ) {
    super(CRITICALNOTICES, http, httpUrlGenerator);
  }

    override getWithQuery(params: any): Observable<NoticesInterface[]> {
        return this.http.get<StaticResponse<NoticesInterface[]>>(noticesURL, { params: params})
            .pipe(map((res: StaticResponse<NoticesInterface[]>) => {
                return res.data;
            }));
    }
}