

export const environment = {
    production: true,
    apiUrlPrefix: '',
    federationAPI_URL: '/assets/config/qa/module-federation.manifest.json',
    commonConfigURL: '/assets/config/common-config-data.json',
    configURL: '/assets/config/qa/config-data.json',
    clientErrorURL: '/assets/config/client-error-data.json',
    fileLocation: 'Discovery',
    EBBName: 'Discovery Gas Transmission LLC',
    MapName: 'Discovery',
    ShortName: 'Discovery',
    ID: '281',
    shortAddress1: 'Discovery Gas Transmission LLC',
    shortAddress2: '2800 Post Oak Boulevard, Level 3,',
    shortAddress3: 'Houston, Texas 77056',
    shortAddress4: 'P.O.Box 1396, Houston, Texas 77251-1396',
    longAddress1: '2800 Post Oak Boulevard, Level 3, Houston, Texas 77056',
    longAddress2: 'P.O.Box 1396, Houston, Texas 77251-1396',
    isFlowing: 'true',
    showContacts: 'true',
    plantName: 'Larose Plant inlet'
};