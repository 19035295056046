import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ContactsGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let condition = false;
        console.log(environment.showContacts);
        if(environment.showContacts === 'true') {
            condition = true;
        }
        if(environment.showContacts === 'false') {
            condition = false;
        }
        return condition ? true : this.router.createUrlTree(['/home']);
    }
}