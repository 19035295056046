<williams-ui-platform-loader *ngIf="!gridData.length" class="center"></williams-ui-platform-loader>
<williams-ui-platform-stack-layout *ngIf="gridData.length" orientation="vertical" class="w-color-compTxt-1" [gap]="'0'">
  <williams-ui-platform-stack-layout class="w-px-8 w-py-4" orientation="horizontal">
    <williams-ui-platform-stack-layout class="w-flex-basis-9 flex-grow-0">
      <h3 class="w-m-0">{{nameOfPage}}</h3>
    </williams-ui-platform-stack-layout>
   <williams-ui-platform-stack-layout orientation="horizontal" [align]="{horizontal:'end'}">
         <span class="w-mr-3">
               <h6 class="w-small-text-bold w-m-0">{{gasTitle}}</h6>
               <h6 class="w-small-text-normal w-m-0">{{discoveryAddress}}</h6>
               <h6 class="w-small-text-normal w-m-0">{{discoveryAddress1}}</h6>
           </span>
           <span class="w-mr-3">
            <img  src="assets/w-ui-angular/images/contact-separator.png" alt="">
           </span>
           <span class="w-mr-3">
                <h6 class="w-small-text-normal w-m-0">Volume Management On Call Number</h6><h6 class="w-small-text-bold w-m-0">
                   {{ contactData.departmentMainContact['Volume Management'] | slice:0:3 }}-{{ contactData.departmentMainContact['Volume Management'] | slice:3:6}}-{{ contactData.departmentMainContact['Volume Management'] | slice:6:10}}
                </h6>
           </span>
           <span class="w-mr-3">
            <img src="assets/w-ui-angular/images/contact-separator.png" alt="">
           </span>
           <span class="">
                <h6 class="w-small-text-normal w-m-0">Pipeline Control Main Number</h6><h6 class="w-small-text-bold w-m-0">
                  {{ contactData.departmentMainContact['Pipeline Control'] | slice:0:3 }}-{{ contactData.departmentMainContact['Pipeline Control'] | slice:3:6}}-{{ contactData.departmentMainContact['Pipeline Control'] | slice:6:10}}
                </h6>
           </span>
  </williams-ui-platform-stack-layout>
  </williams-ui-platform-stack-layout>
  <williams-ui-platform-stack-layout class="w-px-8 w-py-4 w-bg-white-pt9">
    <williams-ui-platform-stack-layout orientation="vertical">
        <williams-ui-platform-stack-layout [align]="{horizontal:'end'}">
          <williams-ui-platform-button
           fillMode="outline"
           classList="d-flex w-h1-bold w-py-1"
            themeColor="primary"
            [imageUrl]="csvIcon"
            (mouseover)="csvIcon=activedCsvIcon"
            (mouseout)="csvIcon=hoveredCsvIcon"
           (click)="export()">
            {{downloadCsv}}
          </williams-ui-platform-button>
        </williams-ui-platform-stack-layout>
        <williams-ui-platform-stack-layout>
            <williams-ui-platform-grid 
            #contactsData 
            [gridData]="gridData" 
            [height]="(36*gridData.length)+88"
            [gridStateSettings]="{take:gridData.length,aggregates:[]}"
            [gridColumnDefs]="gridColumnDef"
            [showActionButtons]="false"
            [showBulkCopy]="false" 
            [showBulkDelete]="false" 
            [enableSelection]="false"
            [showQuickAdd]="false"
            [showBulkEdit]="false" 
            [gridRowCommands]="[]" 
            [showCommandColumn]="false"
            [showAggregate]="true" 
            [groupable]="false"
            [sortable]="false" 
            scrollable="none"
            (hyperlinkClick)="onHyperlinkClick($event)"
            [columnClassCallbackFn]="linkColor"
            csvFileName="contacts.csv"
            [columnsReorderable]="false"
            >
          </williams-ui-platform-grid>
      </williams-ui-platform-stack-layout>
    </williams-ui-platform-stack-layout>
  </williams-ui-platform-stack-layout>
</williams-ui-platform-stack-layout>
