<kendo-breadcrumb style="background-color:#EEEEF0"
    #kendoBreadCrumbInstance="kendoBreadCrumb"
    [items]="items"
    [collapseMode]="collapseMode"
    [separatorIcon]="separatorIcon"
    (itemClick)="onItemClick($event)"
    [ngStyle] = "show ? {'display': 'none'} : {'display': ''}"
>
    <ng-template kendoBreadCrumbItemTemplate let-item let-index="index" *ngIf="breadCrumbTemplateDirective?.breadCrumbTemplate">
        <ng-container *ngTemplateOutlet="breadCrumbTemplateDirective?.breadCrumbTemplate; context: { $implicit: item, item, index }" >
        </ng-container>
    </ng-template>
    <ng-template kendoBreadCrumbItemTemplate let-item let-index="index">
    <span role="link" title="{{item.title}}" tabindex="0" aria-disabled="false" class="k-cursor-pointer k-breadcrumb-root-link ng-star-inserted">
      <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
      <span class="k-breadcrumb-item-text">{{ item.text }}</span>
    </span>
    </ng-template>
</kendo-breadcrumb>