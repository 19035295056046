import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { IndexOfCustomersService } from '../store/services/index-of-customers.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'williams-ui-platform-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    imageUrl: SafeResourceUrl = '';
    isPdfLoaded = false;

    constructor(private customerService: IndexOfCustomersService) {}

    ngOnInit(): void {
        this.customerService
            .fetchImageFileData('sitemap.png', environment.fileLocation)
            .pipe(takeUntil(this.destroy$))
            .subscribe((res: any) => {
                this.imageUrl = res;
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}